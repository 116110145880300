// BlogList.js

import { useEffect, useState } from "react";
import { getPostList } from "../components/utils/postpocketbase";
import { Link } from "react-router-dom";

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para generar el slug
  const slugify = (text) => {
    return text
      .toString()
      .normalize("NFD") // Descompone caracteres Unicode
      .replace(/[\u0300-\u036f]/g, "") // Elimina acentos
      .toLowerCase()
      .replace(/\s+/g, "-") // Reemplaza espacios por guiones
      .replace(/[^\w\-]+/g, "") // Elimina caracteres especiales
      .replace(/\-\-+/g, "-") // Reemplaza múltiples guiones por uno
      .trim();
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const result = await getPostList();
        setPosts(result.items);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-black text-xl">Loading...</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 min-h-screen">
      <div className="py-8"></div>
      <h1 className="text-5xl font-bold text-black mb-8 text-center">
        TCGSE
        <span className="text-red-600 text-2xl px-3 align-top">NEWS</span>
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {posts.map((post) => {
          const slug = slugify(post.title);
          return (
            <Link
              to={`/articles/${slug}`}
              key={post.id}
              className="transform hover:scale-105 transition-transform duration-200"
            >
              <div className="p-6 rounded-lg shadow-xl hover:shadow-xl transition-shadow duration-300">
                <div className="h-48 w-full overflow-hidden rounded-lg">
                  <div className="w-full h-full flex flex-col justify-center items-center">
                    <div className="flex flex-row px-3 py-1 mb-2">
                      {/* <div className="flex flex-row bg-red-500 px-3 py-1 rounded-full mb-2"> */}
                      <h2 className="text-[16px] text-red-600 font-bold">
                        {new Date(post.created).toLocaleDateString("es-ES")}
                      </h2>
                    </div>
                    <h1 className="text-[24px] cygre font-bold text-black text-center px-4 py-2">
                      {post.title}
                    </h1>
                  </div>
                </div>
                <div className="mt-4 flex gap-2 font-bold text-white flex-wrap py-2 justify-center">
                  {["tag_1", "tag_2", "tag_3"].map((tag, index) =>
                    post[tag] ? (
                      <span
                        key={index}
                        className={`text-[14px] px-3 py-1 rounded-full ${
                          tag === "tag_1"
                            ? "bg-gray-900"
                            : tag === "tag_2"
                            ? "bg-gray-700"
                            : "bg-gray-500"
                        }`}
                      >
                        {post[tag]}
                      </span>
                    ) : null
                  )}
                </div>
                <p className="text-black font-semibold text-[18px] mt-2">
                  {post.short_description}
                </p>
                <div className="mt-4 flex justify-end">
                  <span className="text-[14px] text-gray-500 font-bold px-3 py-1 rounded-full">
                    Read more
                  </span>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default BlogList;
