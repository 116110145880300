// ./src/components/actions/Sidebar.js

import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className="relative">
            {/* Filters deactivated */}
            {/*  <button
                onClick={toggleSidebar}
                className="fixed top-4 left-4 bg-blue-600 p-2 rounded z-50"
            >
                {isOpen ? '' : 'Filters'}
            </button> */}
            <motion.div
                ref={sidebarRef}
                className={`py-8 fixed top-0 left-0 h-full bg-blue-900 bg-opacity-80 shadow-lg overflow-y-auto z-40 ${isOpen ? 'block' : 'hidden'}`}
                initial={{ width: 0 }}
                animate={{ width: isOpen ? 300 : 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="p-4">
                    <button
                        onClick={toggleSidebar}
                        className="absolute top-2 right-2 bg-red-600 p-2 rounded"
                    >
                        X
                    </button>
                    {children}
                </div>
            </motion.div>
        </div>
    );
};

export default Sidebar;
