// ./src/hooks/useCardQuantities.js

import { useCallback } from 'react';

export const useCardQuantities = (selectedCards, cardQuantities, setCardQuantities, setSelectedCards) => {

    const increaseQuantity = useCallback((cardId) => {
        setCardQuantities((prevQuantities) => ({
            ...prevQuantities,
            [cardId]: (prevQuantities[cardId] || 0) + 1
        }));
    }, [setCardQuantities]);

    const decreaseQuantity = useCallback((cardId) => {
        setCardQuantities((prevQuantities) => {
            const newQuantities = { ...prevQuantities };
            if (newQuantities[cardId] > 1) {
                newQuantities[cardId]--;
            } else {
                // Si la cantidad baja a 0, eliminamos la carta
                delete newQuantities[cardId];
                // También deberíamos eliminar la carta de `selectedCards` si baja a 0
                setSelectedCards((prevSelectedCards) =>
                    prevSelectedCards.filter(card => card.id !== cardId)
                );
            }
            return newQuantities;
        });
    }, [setCardQuantities, setSelectedCards]);

    const handleShare = useCallback(() => {
        const cardIds = selectedCards.flatMap(card => Array(cardQuantities[card.id]).fill(card.id)).join(',');
        const shareUrl = `https://tcgse.com/share/${cardIds}`;
        navigator.clipboard.writeText(shareUrl).then(() => {
            alert('Share link copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    }, [selectedCards, cardQuantities]);

    return { increaseQuantity, decreaseQuantity, handleShare };
};
