// CardComponent.jsx

import React, { useEffect, useState, useRef } from "react";
import "./CardComponent.css";

const CardComponent = ({ id }) => {
  const [cardData, setCardData] = useState(null);
  const cardRef = useRef(null);
  const hoverStyleRef = useRef(null);

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(
          `https://api.pokemontcg.io/v2/cards/${id}`,
          {
            method: "GET",
            headers: {
              "X-API-KEY": apiKey,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCardData(data);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };
    fetchCard();
  }, [id]);

  useEffect(() => {
    const card = cardRef.current;
    const hoverStyle = hoverStyleRef.current;
    if (!card || !hoverStyle) return;

    const handleMouseMove = (e) => {
      let pos = [e.clientX, e.clientY];
      if (e.type === "touchmove") {
        pos = [e.touches[0].clientX, e.touches[0].clientY];
      }
      const rect = card.getBoundingClientRect();
      let l = pos[0] - rect.left;
      let t = pos[1] - rect.top;
      let h = card.offsetHeight;
      let w = card.offsetWidth;
      let px = Math.abs(Math.floor((100 / w) * l) - 100);
      let py = Math.abs(Math.floor((100 / h) * t) - 100);
      let pa = 50 - px + (50 - py);
      let lp = 50 + (px - 50) / 1.5;
      let tp = 50 + (py - 50) / 1.5;
      let px_spark = 50 + (px - 50) / 7;
      let py_spark = 50 + (py - 50) / 7;
      let p_opc = 20 + Math.abs(pa) * 1.5;
      let ty = ((tp - 50) / 2) * -1;
      let tx = ((lp - 50) / 1.5) * 0.5;
      let tf = `rotateX(${ty}deg) rotateY(${tx}deg)`;

      let style = `
        .card:hover:before { background-position: ${lp}% ${tp}%; }
        .card:hover:after { background-position: ${px_spark}% ${py_spark}%; opacity: ${
        p_opc / 100
      }; }
      `;

      card.style.transform = tf;
      hoverStyle.innerHTML = style;
    };

    const handleMouseOut = () => {
      card.style.transform = "";
      hoverStyle.innerHTML = "";
    };

    card.addEventListener("mousemove", handleMouseMove);
    card.addEventListener("mouseout", handleMouseOut);

    return () => {
      card.removeEventListener("mousemove", handleMouseMove);
      card.removeEventListener("mouseout", handleMouseOut);
    };
  }, [cardData]);

  return (
    <div className="card-container">
      <div className="card" ref={cardRef}>
        {cardData && (
          <img
            src={cardData.data.images.small}
            alt={cardData.data.name}
            loading="lazy"
          />
        )}
      </div>
      <style ref={hoverStyleRef}></style>
    </div>
  );
};

export default CardComponent;
