// ./src/hooks/useSpotlights.js

import { useContext } from "react";
import { CardContext } from "../objects/CardContext";

export const useSpotlights = () => {
  const { pb } = useContext(CardContext);

  const createSpotlightRecord = async ({ name, type, regulationMark }) => {
    const userId = pb.authStore.model?.id || null;
    await pb.collection("spotlights").create({
      user: userId,
      card: name,
      type: type || "None",
      regulation: regulationMark || "Any Regulation",
    });
  };

  return createSpotlightRecord;
};

export default useSpotlights;
