import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';

// Initialize PocketBase client
const pb = new PocketBase('https://fwk4s4o.app-palma.teide.app');

const Dashboard = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Función para obtener el primer usuario disponible
        const fetchFirstAvailableUser = async () => {
            try {
                // Intentar obtener la lista completa de usuarios
                const records = await pb.collection('users').getFullList();

                if (records.length > 0) {
                    // Si hay usuarios, mostrar el nombre del primer usuario
                    setEmail(records[0].email);
                } else {
                    // Si no hay usuarios, mostrar un mensaje de error
                    setError('No users found.');
                }
            } catch (error) {
                // Manejar diferentes tipos de errores
                if (error.isClientResponseError) {
                    setError('Failed to fetch user data from the server.');
                } else {
                    setError('An unexpected error occurred.');
                }
                console.error('Error fetching user records:', error);
            }
        };

        fetchFirstAvailableUser();
    }, []);

    return (
        <div className="flex flex-col ledscreen items-center justify-center h-screen text-white">
            <h1>Settings</h1>
            <p>Email: {email}</p>
        </div>
    );
};

export default Dashboard;
