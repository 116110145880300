// BlogPost.js

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPostList } from "../components/utils/postpocketbase";
import RandomAd from "../components/utils/randomAd";
import ReactMarkdown from "react-markdown";

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  // Función para generar el slug
  const slugify = (text) => {
    return text
      .toString()
      .normalize("NFD") // Descompone caracteres Unicode
      .replace(/[\u0300-\u036f]/g, "") // Elimina acentos
      .toLowerCase()
      .replace(/\s+/g, "-") // Reemplaza espacios por guiones
      .replace(/[^\w\-]+/g, "") // Elimina caracteres especiales
      .replace(/\-\-+/g, "-") // Reemplaza múltiples guiones por uno
      .trim();
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // Obtener todos los posts
        const result = await getPostList();
        const posts = result.items;

        // Buscar el post que coincida con el slug
        const foundPost = posts.find((p) => slugify(p.title) === slug);

        if (foundPost) {
          // Limpiar el body si es necesario
          if (foundPost.body) {
            foundPost.body = foundPost.body.replace(/【\d+†source】/g, "");
          }
          setPost(foundPost);
        } else {
          setPost(null);
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl">Post not found</p>
      </div>
    );
  }

  const featuredImage = post.featured_image
    ? `https://ows4kkks4c8w8skc84o444s4.app-palma.teide.app/api/files/ytdere55soa9gpo/${post.id}/${post.featured_image}`
    : null;
  const firstImage = post.first_image
    ? `https://ows4kkks4c8w8skc84o444s4.app-palma.teide.app/api/files/ytdere55soa9gpo/${post.id}/${post.first_image}`
    : null;

  // Dividir el body en párrafos
  const paragraphs = post.body.split(/\n\n+/);

  return (
    <div className="container mx-auto p-6 min-h-screen py-36 text-black">
      <header className="text-center mb-8">
        <h1 className="text-4xl cygre font-bold">
          {post.title || "Title not available"}
        </h1>
        <p className="font-bold cygre mt-2">
          {post.short_description || "Description not available"}
        </p>
        <div className="flex text-white justify-center flex-wrap gap-2 mt-4">
          {["tag_1", "tag_2", "tag_3"].map((tag, index) =>
            post[tag] ? (
              <span
                key={index}
                className={`text-sm font-medium px-3 py-1 rounded-full ${
                  tag === "tag_1"
                    ? "bg-gray-900"
                    : tag === "tag_2"
                    ? "bg-gray-700"
                    : "bg-gray-500"
                }`}
              >
                {post[tag]}
              </span>
            ) : null
          )}
        </div>
      </header>

      <main className="flex flex-col items-center">
        <div className="max-w-3xl w-full">
          {featuredImage && (
            <img
              src={featuredImage}
              alt={post.title}
              className="rounded-lg shadow-lg mb-6 w-full object-cover"
            />
          )}

          <div className="prose prose-invert space-y-6 font-semibold xl:text-[20px] md:text-[18px] sm:text-[16px] text-justify">
            {/* description */}
            {post.description && (
              <div>
                <ReactMarkdown>{post.description}</ReactMarkdown>
              </div>
            )}
            {paragraphs.map((paragraph, index) => (
              <div key={index}>
                <ReactMarkdown>{paragraph}</ReactMarkdown>
                {/* Insertar RandomAd después de cada 4 párrafos */}
                {(index + 1) % 4 === 0 && index !== paragraphs.length - 1 && (
                  <RandomAd />
                )}
              </div>
            ))}
          </div>

          {firstImage && (
            <img
              src={firstImage}
              alt={`${post.title} - Additional Image`}
              className="rounded-lg shadow-lg mt-6 w-full object-cover"
            />
          )}
        </div>
        {/* {post.podcast && (
          <div className="max-w-3xl w-full mt-8">
            <h3 className="text-2xl font-semibold mb-2">Podcast</h3>
            <audio controls className="w-full">
              <source src={post.podcast} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )} */}
      </main>

      <div className="flex justify-center mt-8">
        <button
          onClick={() => window.history.back()}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default BlogPost;
