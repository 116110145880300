// ./src/components/actions/Trading.js

import React, { useState, useContext, useEffect } from 'react';
import { CardContext } from '../objects/CardContext';
import PriceComparison from '../algos/PriceComparison';
import { useCardQuantities } from '../hooks/useCardQuantities';
import { useCardSelection } from '../hooks/useCardSelection';
import { useSpotlights } from '../hooks/useSpotlights';
import { typesIcons } from '../utils/typesIcons';
import { habilityIcon } from '../utils/habilityIcon';
import Legend from '../objects/Legend.js';
import { motion } from 'framer-motion';
import SettingsView from '../Views/Filter.js';
import TradingBar from '../ui/Menu/Sidebar.js';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom'; // Import Link for routing

const Trading = () => {
    const { fetchCards, cards } = useContext(CardContext);
    const createSpotlightRecord = useSpotlights();
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [regulationMark, setRegulationMark] = useState('');
    const [isStandardLegal] = useState(false);
    const [lastSearchParams, setLastSearchParams] = useState({ name: '', type, regulationMark, isStandardLegal: false });
    const [error, setError] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState({});
    const [columnOrder, setColumnOrder] = useState([]);
    const [userView, setUserView] = useState(null);
    const [userLevelId, setUserLevelId] = useState('');
    const [allowedColumns, setAllowedColumns] = useState([]);
    const [userHasInfoAccess, setUserHasInfoAccess] = useState(false); // New state to check info column access
    const { selectedCards, cardQuantities, toggleCardSelection, handleRemoveCard, setCardQuantities, setSelectedCards } = useCardSelection();
    const { increaseQuantity, decreaseQuantity } = useCardQuantities(selectedCards, cardQuantities, setCardQuantities, setSelectedCards);

    const pb = new PocketBase('https://fwk4s4o.app-palma.teide.app');
    const [shareLink, setShareLink] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Detectar si la pantalla es menor a 768px de ancho
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Ejecutar inicialmente

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (selectedCards.length > 0) {
            // Generar el enlace de compartición
            const cardIds = selectedCards.map(card => card.id).join(',');
            const generatedLink = `https://tcgse.com/share/${cardIds}`;
            setShareLink(generatedLink);
        }
    }, [selectedCards]);

    const handleShare = async () => {
        try {
            const userId = pb.authStore.model?.id;
            if (!userId) throw new Error('User not authenticated');

            // Obtener los IDs de las cartas seleccionadas
            const cardIds = selectedCards.map(card => card.id);

            const data = {
                user: userId, // Relación con los usuarios
                source: shareLink, // Usar el enlace generado
                cardIds // IDs de las cartas compartidas
            };

            // Guardar en PocketBase
            await pb.collection('shareds').create(data);

            // Copiar el enlace al portapapeles
            await navigator.clipboard.writeText(shareLink);

            // Mostrar alerta indicando que el enlace se ha copiado
            alert('¡Enlace copiado en tu portapapeles! Puedes pegarlo donde quieras.');

            if (!isMobile) {
                // Abrir el enlace en una nueva ventana si no es un dispositivo móvil
                window.open(shareLink, '_blank');
            }

        } catch (error) {
            alert('¡Enlace copiado! Activa pop-ups para una mejor experiencia. Link copied! Enable pop-ups for a better experience.');
        }
    };

    const columns = [
        { key: 'info', label: 'Info' }, // Add the 'info' column to the columns array
        { key: 'deck', label: 'Deck' },
        { key: 'card', label: 'Card' },
        { key: 'trend_avg', label: 'Trend / Avg' },
        { key: 'low', label: 'Low' },
        { key: 'low_ex', label: 'Low Ex+' },
        { key: 'avg_sell', label: 'Avg Sell' },
        { key: 'trend', label: 'Trend' },
        { key: 'avg_market', label: 'Avg Market' },
        { key: 'low_tcplayer', label: 'Low US/LATAM' },
        { key: 'mid_tcplayer', label: 'Mid US/LATAM' },
        { key: 'high_tcplayer', label: 'High US/LATAM' },
        { key: 'low_direct', label: 'Low Direct' },
        { key: 'rev_holo_market', label: 'Rev Holo Market' },
        { key: 'rev_holo_low', label: 'Rev Holo Low' },
        { key: 'rev_holo_mid', label: 'Rev Holo Mid' },
        { key: 'rev_holo_high', label: 'Rev Holo High' },
        { key: 'rev_holo_direct_low', label: 'Rev Holo Direct Low' },
        { key: 'release_date', label: 'Release Date' },
        { key: 'name_number', label: 'Name - Number' },
        { key: 'set', label: 'Set' },
        { key: 'reg', label: 'Reg' },
        { key: 'type', label: 'Type' },
        { key: 'ability', label: 'Ability' },
        { key: 'buy', label: 'Buy' }
    ];

    useEffect(() => {
        const fetchUserLevel = async () => {
            const userId = pb.authStore.model?.id;
            if (userId) {
                try {
                    const userInfo = await pb.collection('users').getOne(userId);
                    const levelId = userInfo.level || '';
                    setUserLevelId(levelId);

                    // Check if the user has the 'stage 2' level
                    if (levelId === 'c0p250cohr640qa') {
                        setUserHasInfoAccess(true);
                    }
                } catch (error) {
                    console.error('Failed to fetch user level:', error);
                    setUserLevelId('');
                }
            } else {
                setUserLevelId('');
            }
        };

        const fetchAllowedColumns = async () => {
            try {
                const result = await pb.collection('features').getFullList({
                    sort: 'item',
                });

                // Obtener las características permitidas según el nivel de usuario y si están activadas
                const allowedColumnKeys = result
                    .filter(item => item.activated || item.level === userLevelId)
                    .map(item => item.item);

                setAllowedColumns(allowedColumnKeys);

                const initialVisibleColumns = columns.reduce((acc, column) => {
                    // Only include the 'info' column if the user has access
                    if (column.key !== 'info' || (column.key === 'info' && userHasInfoAccess)) {
                        acc[column.key] = allowedColumnKeys.includes(column.key);
                    }
                    return acc;
                }, {});
                setVisibleColumns(initialVisibleColumns);

            } catch (error) {
                console.error('Failed to fetch allowed columns:', error);
            }
        };

        const fetchViewSettings = async () => {
            const userId = pb.authStore.model?.id;
            if (!userId) return;

            try {
                const result = await pb.collection('views').getFullList({
                    filter: `user='${userId}'`
                });
                if (result.length > 0) {
                    const userView = result[0];
                    setUserView(userView);
                    setVisibleColumns(prevState => ({
                        ...prevState,
                        ...userView
                    }));
                    setColumnOrder(Object.keys(userView).filter(col => col !== 'id' && col !== 'user'));
                }
            } catch (error) {
                console.error('Failed to fetch view settings:', error);
            }
        };

        fetchUserLevel();
        fetchAllowedColumns();
        fetchViewSettings();
    }, [userLevelId, userHasInfoAccess]);

    const handleColumnVisibilityChange = (column) => {
        setVisibleColumns(prevState => ({
            ...prevState,
            [column]: !prevState[column]
        }));
    };

    const handleSaveViewSettings = async () => {
        const userId = pb.authStore.model?.id;
        if (!userId) return;

        try {
            const settingsToSave = { ...visibleColumns, user: userId };
            if (userView) {
                await pb.collection('views').update(userView.id, settingsToSave);
            } else {
                const newView = await pb.collection('views').create(settingsToSave);
                setUserView(newView);
            }
            alert('View settings saved successfully!');
        } catch (error) {
            console.error('Failed to save view settings:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newSearchParams = { name, type, regulationMark, isStandardLegal };

        if (JSON.stringify(newSearchParams) !== JSON.stringify(lastSearchParams)) {
            await createSpotlightRecord(newSearchParams);
            await fetchCards(newSearchParams);
            setLastSearchParams(newSearchParams);
        }
    };

    const handleExport = () => {
        const formatCard = (card) => {
            const quantity = cardQuantities[card.id];
            const trendPrice = parseFloat(card.cardmarket?.prices?.trendPrice || 0).toFixed(2);
            const totalTrendPrice = (quantity * parseFloat(trendPrice)).toFixed(2);
            const averageSellPrice = parseFloat(card.cardmarket?.prices?.averageSellPrice || 0).toFixed(2);
            const totalAverageSellPrice = (quantity * parseFloat(averageSellPrice)).toFixed(2);
            return `${card.name} ${card.set.id} ${card.number}/${card.set.total} x${quantity} | ~${totalTrendPrice} €, ${totalAverageSellPrice} €`;
        };

        const groupedCards = selectedCards.reduce((acc, card) => {
            const category = card.supertype === 'Pokémon' ? 'Pokémon' : card.supertype === 'Trainer' ? 'Trainer' : 'Energy';
            if (!acc[category]) acc[category] = [];
            acc[category].push(formatCard(card));
            return acc;
        }, {});

        let exportText = '';
        for (const [category, cards] of Object.entries(groupedCards)) {
            exportText += `${category}: ${cards.length}\n${cards.join('\n')}\n\n`;
        }

        const totalTrendPrice = selectedCards.reduce((acc, card) => acc + cardQuantities[card.id] * parseFloat(card.cardmarket?.prices?.trendPrice || 0), 0);
        const totalAverageSellPrice = selectedCards.reduce((acc, card) => acc + cardQuantities[card.id] * parseFloat(card.cardmarket?.prices?.averageSellPrice || 0), 0);
        exportText += `Total: ~${totalTrendPrice.toFixed(2)} €, ${totalAverageSellPrice.toFixed(2)} €\n`;
        exportText += `\n Fair Prices for Fair Trades`;
        exportText += `\n Share your deck: https://tcgse.com/share/${selectedCards.flatMap(card => Array(cardQuantities[card.id]).fill(card.id)).join(',')}`;
        exportText += `\n Source: https://tcgse.com | TCG Stock Exchange`;

        navigator.clipboard.writeText(exportText).then(() => {
            alert('Card list copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    /* Now to export the link */
    const handleLink = () => {
        navigator.clipboard.writeText(shareLink).then(() => {
            alert('Link copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy link: ', err);
        });
    };

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const getColumnContent = (card, column) => {
        switch (column.key) {
            case 'info':
                if (userHasInfoAccess) { // Check if the user has access to the 'info' column
                    const tcgseidBase = `${card.set.id}/${card.set.id}-${card.number}`;
                    /* const tcgseidFull = `${tcgseidBase}/${card.set.id}_${card.number}`; */
                    return (
                        <Link to={`/card/${tcgseidBase}/${card.set.id}_${card.number}`} className="text-blue-500 underline">
                            Info
                        </Link>
                    );
                }
                return '-';
            case 'deck':
                return (
                    <div className="flex items-center justify-center">
                        {cardQuantities[card.id] > 0 ? (
                            <>
                                <button
                                    className="bg-red-400 py-2 text-black px-5 rounded"
                                    onClick={() => decreaseQuantity(card.id)}
                                >
                                    -
                                </button>
                                <input
                                    type="number"
                                    value={cardQuantities[card.id]}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value, 10);
                                        if (value > 0) {
                                            setCardQuantities((prevQuantities) => ({
                                                ...prevQuantities,
                                                [card.id]: value
                                            }));
                                        }
                                    }}
                                    className="bg-black w-12 text-center mx-2 rounded"
                                />
                                <button
                                    className="bg-green-400 py-2 text-black px-5 rounded"
                                    onClick={() => increaseQuantity(card.id)}
                                >
                                    +
                                </button>
                            </>
                        ) : (
                            <button
                                className="bg-green-400 text-black p-2 rounded"
                                onClick={() => toggleCardSelection(card)}
                            >
                                Add
                            </button>
                        )}
                    </div>
                );
            case 'card':
                return (
                    <a href={card.cardmarket?.url} target="_blank" rel="noreferrer"
                        className="flex flex-col items-center">
                        <span className="flex text-[10px]">{card.name}</span>
                        <img src={card.images.small} alt={card.name} className="max-w-24 p-1 rounded-[7px] shadow-lg inline-block hover:scale-105" />
                        <span className="flex text-[10px]">{card.regulationMark}
                            &nbsp;
                            {card.number}/{card.set.total}
                        </span>
                        &nbsp;
                    </a>
                );
            case 'trend_avg':
                return (
                    <PriceComparison
                        trendPrice={card.cardmarket?.prices?.trendPrice}
                        averageSellPrice={card.cardmarket?.prices?.averageSellPrice}
                    />
                );
            case 'low':
                return card.cardmarket?.prices?.lowPrice ? `${card.cardmarket.prices.lowPrice.toFixed(2)} €` : '-';
            case 'low_ex':
                return card.cardmarket?.prices?.lowPriceExPlus ? `${card.cardmarket.prices.lowPriceExPlus.toFixed(2)} €` : '-';
            case 'avg_sell':
                return card.cardmarket?.prices?.averageSellPrice ? `${card.cardmarket.prices.averageSellPrice.toFixed(2)} €` : '-';
            case 'trend':
                return card.cardmarket?.prices?.trendPrice ? `${card.cardmarket.prices.trendPrice.toFixed(2)} €` : '-';
            case 'avg_market':
                return card.tcgplayer?.prices?.normal?.market ? `${card.tcgplayer.prices.normal.market.toFixed(2)} $` : '-';
            case 'low_tcplayer':
                return card.tcgplayer?.prices?.normal?.low ? `${card.tcgplayer.prices.normal.low.toFixed(2)} $` : '-';
            case 'mid_tcplayer':
                return card.tcgplayer?.prices?.normal?.mid ? `${card.tcgplayer.prices.normal.mid.toFixed(2)} $` : '-';
            case 'high_tcplayer':
                return card.tcgplayer?.prices?.normal?.high ? `${card.tcgplayer.prices.normal.high.toFixed(2)} $` : '-';
            case 'low_direct':
                return card.tcgplayer?.prices?.normal?.directLow ? `${card.tcgplayer.prices.normal.directLow.toFixed(2)} $` : '-';
            case 'rev_holo_market':
                return card.tcgplayer?.prices?.reverseHolofoil?.market ? `${card.tcgplayer.prices.reverseHolofoil.market.toFixed(2)} $` : '-';
            case 'rev_holo_low':
                return card.tcgplayer?.prices?.reverseHolofoil?.low ? `${card.tcgplayer.prices.reverseHolofoil.low.toFixed(2)} $` : '-';
            case 'rev_holo_mid':
                return card.tcgplayer?.prices?.reverseHolofoil?.mid ? `${card.tcgplayer.prices.reverseHolofoil.mid.toFixed(2)} $` : '-';
            case 'rev_holo_high':
                return card.tcgplayer?.prices?.reverseHolofoil?.high ? `${card.tcgplayer.prices.reverseHolofoil.high.toFixed(2)} $` : '-';
            case 'rev_holo_direct_low':
                return card.tcgplayer?.prices?.reverseHolofoil?.directLow ? `${card.tcgplayer.prices.reverseHolofoil.directLow.toFixed(2)} $` : '-';
            case 'release_date':
                return card.set?.releaseDate ? card.set.releaseDate : '-';
            case 'name_number':
                return `${card.name} - ${card.number}/${card.set.total}`;
            case 'set':
                return card.set?.name ? card.set.name : '-';
            case 'reg':
                return card.regulationMark ? card.regulationMark : '-';
            case 'type':
                return (
                    <div className="flex justify-center">
                        {card.types?.map(type => (
                            <img key={type} src={typesIcons[type]} alt={type} className="h-6 rounded-full mx-1" />
                        )) || '-'}
                    </div>
                );
            case 'ability':
                return card.abilities ? (
                    <img src={habilityIcon.hability} alt="ability" className="h-8 rounded-full" />
                ) : '-';
            case 'buy':
                return card.cardmarket && (
                    <a
                        href={card.cardmarket?.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500"
                    >
                        <svg
                            className="h-6 w-6"
                            viewBox="0 0 21 21"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g fill="none" fillRule="evenodd" transform="translate(2 4)">
                                <path
                                    d="m3 2.5h12.5l-1.5855549 5.54944226c-.2453152.85860311-1.0300872 1.45055774-1.9230479 1.45055774h-6.70131161c-1.01909844 0-1.87522688-.76627159-1.98776747-1.77913695l-.80231812-7.22086305h-2"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <g fill="currentColor">
                                    <circle cx="5" cy="12" r="1" />
                                    <circle cx="13" cy="12" r="1" />
                                </g>
                            </g>
                        </svg>
                    </a>
                );
            default:
                return '-';
        }
    };

    return (
        <div className="ledscreen min-h-screen flex">
            <TradingBar>
                <SettingsView
                    columns={columns}
                    visibleColumns={visibleColumns}
                    handleColumnVisibilityChange={handleColumnVisibilityChange}
                    handleSaveViewSettings={handleSaveViewSettings}
                />
            </TradingBar>
            <div className="flex-grow overflow-auto mt-4 ml-0">
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col px-8 py-4 xl:flex-row md:flex-row sm:flex-col gap-2 items-center justify-between w-full">
                        <div className="hidden md:flex flex-row gap-2 items-center justify-center">
                            <h1 className="sm:text-sm md:text-xl font-bold text-center text-green-400">TCGSE.com | TCG Stock Exchange</h1>
                        </div>
                        <div className="flex flex-row gap-2 items-center justify-evenly">
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="bg-black hover:placeholder: text-center border-white border-2 rounded-full w-full max-w-xs p-4"
                                placeholder="Search by Name"
                            />
                            <div className="flex w-1/3">
                                <button
                                    type="submit"
                                    className="bg-green-700 hover:bg-green-900 text-center border-white border-2 rounded-full w-full max-w-xs p-4"
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 items-center justify-evenly">
                            <select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className="flex bg-black border-white border-2 rounded-lg w-full px-[7px] py-[7px]"
                            >
                                <option value="">Select Type</option>
                                {['Grass', 'Fire', 'Water', 'Lightning', 'Fighting', 'Psychic', 'Colorless', 'Darkness', 'Metal', 'Dragon', 'Fairy'].map(t => <option key={t} value={t}>{t}</option>)}
                            </select>
                            <select
                                value={regulationMark}
                                onChange={(e) => setRegulationMark(e.target.value)}
                                className="flex bg-black border-white border-2 rounded-lg w-full px-[7px] py-[7px]"
                            >
                                <option value="">Any Regulation</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                <option value="F">F</option>
                                <option value="G">G</option>
                                <option value="H">H</option>
                            </select>
                        </div>
                        <div className="text-xl">
                            {cards.length === 0 && (
                                <p className="text-orange-500">0 <span className="">Cards</span></p>
                            )}
                            {cards.length > 0 && (
                                <p className="text-green-500">{cards.length} <span className="">Cards</span></p>
                            )}
                            {error && (
                                <p className="text-red-500">Error</p>
                            )}
                        </div>
                    </div>
                </form>
                <Legend />
                <div className="overflow-auto mt-4">
                    <table className="w-full text-center">
                        <thead className="bg-[FFFFFF60]-700 rounded-t-lg">
                            <tr className="text-xs sm:text-sm lg:text-base text-gray-300 uppercase tracking-wider">
                                {userHasInfoAccess && visibleColumns['info'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Info</th>}
                                {visibleColumns['deck'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Deck</th>}
                                {visibleColumns['card'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Card</th>}
                                {visibleColumns['trend_avg'] && <th className="py-3 px-11 bg-purple-700 border-r border-[#FFFFFF60]">Trend / Avg</th>}
                                {visibleColumns['low'] && <th className="py-3 px-11 bg-purple-700 border-r border-[#FFFFFF60]">Low</th>}
                                {visibleColumns['low_ex'] && <th className="py-3 px-11 bg-purple-700 border-r border-[#FFFFFF60]">Low Ex+</th>}
                                {visibleColumns['avg_sell'] && <th className="py-3 px-11 bg-purple-700 border-r border-[#FFFFFF60]">Avg Sell</th>}
                                {visibleColumns['trend'] && <th className="py-3 px-11 bg-purple-700 border-r border-[#FFFFFF60]">Trend</th>}
                                {visibleColumns['avg_market'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Avg Market</th>}
                                {visibleColumns['low_tcplayer'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Low</th>}
                                {visibleColumns['mid_tcplayer'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Mid</th>}
                                {visibleColumns['high_tcplayer'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">High</th>}
                                {visibleColumns['low_direct'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Low Direct</th>}
                                {visibleColumns['rev_holo_market'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Rev Holo Market</th>}
                                {visibleColumns['rev_holo_low'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Rev Holo Low</th>}
                                {visibleColumns['rev_holo_mid'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Rev Holo Mid</th>}
                                {visibleColumns['rev_holo_high'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Rev Holo High</th>}
                                {visibleColumns['rev_holo_direct_low'] && <th className="py-3 px-11 bg-blue-700 border-r border-[#FFFFFF60]">Rev Holo Direct Low</th>}
                                {visibleColumns['release_date'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Release Date</th>}
                                {visibleColumns['name_number'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Name - Number</th>}
                                {visibleColumns['set'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Set</th>}
                                {visibleColumns['reg'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Reg</th>}
                                {visibleColumns['type'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Type</th>}
                                {visibleColumns['ability'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Ability</th>}
                                {visibleColumns['buy'] && <th className="py-3 px-4 bg-[00000060] border-r border-[#FFFFFF60]">Buy</th>}
                            </tr>
                        </thead>
                        <motion.tbody
                            key={lastSearchParams}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2, duration: 0.5 }}
                            className="divide-y divide-dark-700 bg-[FFFFFF60]"
                        >
                            {cards.map((card, index) => (
                                <motion.tr
                                    key={index}
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 0.5 + index * 0.1, duration: 0.5 }}
                                    className={`bg-opacity-80 ${['F', 'G', 'H'].includes(card.regulationMark) ? 'bg-green-800 bg-opacity-45' : 'bg-black'}`}
                                >
                                    {columns.map(column => (
                                        visibleColumns[column.key] && (
                                            <td
                                                key={column.key}
                                                className="hover:bg-blue-600 hover:bg-opacity-30 py-3 px-4 text-xs sm:text-sm lg:text-base text-gray-200 border-b border-[#FFFFFF60]"
                                            >
                                                {getColumnContent(card, column)}
                                            </td>
                                        )
                                    ))}
                                </motion.tr>
                            ))}
                        </motion.tbody>
                    </table>
                </div>
                {selectedCards.length > 0 && (
                    <>
                        <div className={`fixed bottom-0 left-0 w-full md:w-1/3 p-4 bg-gray-800 ${isPopupVisible ? 'block' : 'hidden'} rounded-t-lg md:rounded-lg shadow-lg`}>
                            <button
                                onClick={togglePopup}
                                className="text-2xl absolute top-0 right-0 p-2 text-red-500"
                            >
                                X
                            </button>

                            <div className="flex flex-col justify-between items-center text-2xl">
                                <h2 className="text-lg">Selected Cards</h2>
                            </div>
                            <ul className=" mt-2 max-h-48 overflow-y-auto">
                                {selectedCards.map((card, index) => (
                                    <li key={index} className="flex justify-between items-center">
                                        <span>{card.name} - {card.number}/{card.set.total}</span>
                                        <button
                                            className="text-red-500"
                                            onClick={() => handleRemoveCard(card)}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>

                            {!isMobile && (
                                <>
                                    <button onClick={handleShare} className="mt-4 bg-green-700 hover:bg-green-900 w-full p-4 rounded">
                                        Save and Share Deck
                                    </button>
                                    <button onClick={handleLink} className="mt-4 bg-green-700 hover:bg-green-900 w-full p-4 rounded">
                                        Copy Link to Clipboard
                                    </button>
                                </>
                            )}

                            {isMobile && (
                                <button onClick={handleLink} className="mt-4 bg-blue-700 hover:bg-blue-900 w-full p-4 rounded">
                                    Copy Link to Clipboard
                                </button>
                            )}
                        </div>
                        {!isPopupVisible && (
                            <button
                                onClick={togglePopup}
                                className="opacity-80 fixed bottom-4 left-4 px-11 bg-blue-700 hover:bg-blue-900 p-4 rounded-full"
                            >
                                Export
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Trading;
