// LineChart.js

import React from "react";
import { Line } from "react-chartjs-2";

const gradeColors = ["#ff0000", "#ff7f00", "#ffff00", "#7fff00", "#00ff00"];

const calculateGrade = (value, max) => {
  if (max === 0) return 0;
  const grade = Math.round((value / max) * 4);
  return Math.min(Math.max(grade, 0), 4);
};

const LineChart = ({ history, cumulativeValue, globalMax }) => {
  const grade = calculateGrade(cumulativeValue, globalMax);
  const pointColor = gradeColors[grade];

  const dataPoints = history.map((item, index) => {
    if (index === 0) return 0;
    const change = item.PentiumNumber - history[index - 1].PentiumNumber;
    if (change >= 0.5) {
      return 1;
    } else if (change <= -0.5) {
      return -1;
    } else {
      return 0;
    }
  });

  const data = {
    labels: history.map((_, index) => `T ${index + 1}`),
    datasets: [
      {
        label: "Points",
        data: dataPoints,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0,
        pointBackgroundColor: pointColor,
        pointRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            return `Points: ${value > 0 ? "+" : ""}${value}`;
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
        grid: { display: false },
      },
      y: {
        display: true,
        beginAtZero: false,
        grid: { color: "rgba(255, 255, 255, 0.1)" },
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return value > 0 ? `+${value}` : value;
          },
        },
      },
    },
  };

  return (
    <div className="w-full h-64">
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
