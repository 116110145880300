import React from 'react'

export default function Legend() {
    return (
        <div className="flex flex-col gap-2 items-center justify-center">
            <div className="flex flex-row gap-2 items-center justify-center">
                <div className="flex flex-row gap-2 items-center justify-center">
                    <div className="w-4 h-4 bg-purple-700 rounded-full"></div>
                    <p className="text-white">Europe</p>
                </div>
                <div className="flex flex-row gap-2 items-center justify-center">
                    <div className="w-4 h-4 bg-blue-700 rounded-full"></div>
                    <p className="text-white">AMERICAS</p>
                </div>
            </div>
            {/* <div className="flex flex-row gap-2 items-center justify-center">
                <div className="flex flex-row gap-2 items-center justify-center">
                    <div className="w-4 h-4 bg-green-700 rounded-full">
                    </div>
                    <p className="text-white">
                        Legal Standard
                    </p>
                </div>
            </div> */}
        </div>
    )
}

