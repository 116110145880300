// ./src/hooks/useCardSelection.js

import { useState } from 'react';

export const useCardSelection = () => {
    const [selectedCards, setSelectedCards] = useState([]);
    const [cardQuantities, setCardQuantities] = useState({});

    const toggleCardSelection = (card) => {
        setSelectedCards((prevSelectedCards) => {
            if (prevSelectedCards.some(selectedCard => selectedCard.id === card.id)) {
                // Si la carta ya está seleccionada, incrementar su cantidad
                setCardQuantities((prevQuantities) => ({
                    ...prevQuantities,
                    [card.id]: (prevQuantities[card.id] || 0) + 1
                }));
                return prevSelectedCards;
            } else {
                // Si la carta no está seleccionada, agregarla a la lista y establecer su cantidad a 1
                setCardQuantities((prevQuantities) => ({
                    ...prevQuantities,
                    [card.id]: 1
                }));
                return [...prevSelectedCards, card];
            }
        });
    };

    const handleRemoveCard = (card) => {
        setSelectedCards((prevSelectedCards) => prevSelectedCards.filter((selectedCard) => selectedCard.id !== card.id));
        setCardQuantities((prevQuantities) => {
            const newQuantities = { ...prevQuantities };
            delete newQuantities[card.id];
            return newQuantities;
        });
    };

    return { selectedCards, cardQuantities, toggleCardSelection, handleRemoveCard, setCardQuantities, setSelectedCards };
};
