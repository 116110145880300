// InsightsTable.jsx

import React, { useEffect, useState } from "react";
import { fetchTopData } from "./api";
import CardComponent from "./CardComponent";
import MiniChart from "./MiniChart";
import LineChart from "./LineChart";
import { motion, AnimatePresence } from "framer-motion";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const gradeColors = ["#ff0000", "#ff7f00", "#ffff00", "#7fff00", "#00ff00"];

const calculateGrade = (value, max) => {
  if (max === 0) return 0;
  const grade = Math.round((value / max) * 4);
  return Math.min(Math.max(grade, 0), 4);
};

const InsightsTable = () => {
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedCard, setExpandedCard] = useState(null);
  const [historyMap, setHistoryMap] = useState(new Map());
  const [cumulativeMap, setCumulativeMap] = useState(new Map());
  const [globalMax, setGlobalMax] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { latestItems, prevDataMap, cumulativeMap, globalMax } =
          await fetchTopData();
        setInsights(latestItems);
        setHistoryMap(prevDataMap);
        setCumulativeMap(cumulativeMap);
        setGlobalMax(globalMax);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const calculateTrend = (history) => {
    if (history.length > 1) {
      const firstValue = history[0].PentiumNumber;
      const lastValue = history[history.length - 1].PentiumNumber;
      const change = lastValue - firstValue;
      if (change >= 0.5) {
        return 1; // Gana un punto
      } else if (change <= -0.5) {
        return -1; // Pierde un punto
      } else {
        return 0; // Sin cambio significativo
      }
    }
    return 0;
  };

  const getMiniChartData = (history, insightName) => {
    const cumulativeValue = cumulativeMap.get(insightName) || 0;
    const grade = calculateGrade(cumulativeValue, globalMax);
    const pointColor = gradeColors[grade];

    const dataPoints = history.map((item, index) => {
      if (index === 0) return 0;
      const change = item.PentiumNumber - history[index - 1].PentiumNumber;
      if (change >= 0.5) {
        return 1;
      } else if (change <= -0.5) {
        return -1;
      } else {
        return 0;
      }
    });

    return {
      labels: history.map((_, index) => index.toString()),
      datasets: [
        {
          data: dataPoints,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "transparent",
          fill: false,
          tension: 0,
          pointBackgroundColor: pointColor,
          pointRadius: 3,
        },
      ],
    };
  };

  const toggleCard = (name) => {
    setExpandedCard((prev) => (prev === name ? null : name));
  };

  const renderBatteryIcon = (insightName) => {
    const cumulativeValue = cumulativeMap.get(insightName) || 0;
    const grade = calculateGrade(cumulativeValue, globalMax);
    const color = gradeColors[grade];

    return (
      <svg
        height="24"
        viewBox="0 0 21 21"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="translate(2 6)">
          <path
            d="m2.5.5h10c1.1045695 0 2 .8954305 2 2v3c0 1.1045695-.8954305 2-2 2h-10c-1.1045695 0-2-.8954305-2-2v-3c0-1.1045695.8954305-2 2-2z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m3 2h7c.5522847 0 1 .44771525 1 1v2.04629283c0 .55228475-.4477153 1-1 1h-7c-.55228475 0-1-.44771525-1-1v-2.04629283c0-.55228475.44771525-1 1-1z"
            fill={color}
          />
          <path
            d="m16.5 2.5v3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  };

  if (loading) return <p className="text-center text-white">Loading data...</p>;

  return (
    <div className="container mx-auto px-4 py-32 ledscreen">
      <h2 className="text-3xl font-bold mb-6 text-white">
        Markets - TCGSE Points
      </h2>
      <motion.ul
        className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {insights.map((insight) => {
          const history = historyMap.get(insight.name) || [];
          const trend = calculateTrend(history);
          const miniChartData = getMiniChartData(history, insight.name);
          const isExpanded = expandedCard === insight.name;

          return (
            <motion.li
              key={insight.id}
              className={`bg-blue-900 p-5 rounded-lg shadow-lg transition-all ${
                isExpanded ? "col-span-full" : ""
              }`}
              whileHover={{ scale: 1.02 }}
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={() => toggleCard(insight.name)}
              >
                <div className="flex flex-col mr-4 w-32">
                  <div className="flex items-center mb-2">
                    {renderBatteryIcon(insight.name)}
                    <span className="text-md font-semibold text-white ml-2 truncate">
                      {insight.name}
                    </span>
                  </div>
                  <CardComponent id={insight.TCGSEidV3} />
                  {trend !== 0 && (
                    <span className="text-sm text-white mt-2">
                      {trend > 0 ? "+" : ""}
                      {trend} Pts
                    </span>
                  )}
                </div>
                <div className="flex-1">
                  {!isExpanded && <MiniChart data={miniChartData} />}
                </div>
                <div className="ml-4">
                  <motion.svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    animate={{ rotate: isExpanded ? 180 : 0 }}
                  >
                    <path fill="white" d="M12 15.5l-7-7h14l-7 7z" />
                  </motion.svg>
                </div>
              </div>
              <AnimatePresence>
                {isExpanded && history.length > 0 && (
                  <motion.div
                    className="mt-6"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    <LineChart
                      history={history}
                      cumulativeValue={cumulativeMap.get(insight.name) || 0}
                      globalMax={globalMax}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.li>
          );
        })}
      </motion.ul>
      <p className="text-center text-white mt-8 py-8">
        This is not financial advice
      </p>
    </div>
  );
};

export default InsightsTable;
