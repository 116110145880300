// api.js

import PocketBase from "pocketbase";
import Cookies from "js-cookie";

const pb = new PocketBase("https://ows4kkks4c8w8skc84o444s4.app-palma.teide.app/");

export const fetchTopData = async () => {
  try {
    const cachedData = Cookies.get("topData");
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      return {
        latestItems: parsedData.latestItems,
        prevDataMap: new Map(parsedData.prevDataMap),
        cumulativeMap: new Map(parsedData.cumulativeMap),
        globalMax: parsedData.globalMax,
      };
    }

    const allData = await pb.collection("insights").getFullList(50, {
      sort: "-PentiumNumber,-PentiumOrigine",
    });

    const excludedNames = [
      "Basic Fighting Energy",
      "Basic Lightning Energy",
      "Basic Grass Energy",
      "Basic Water Energy",
      "Basic Psychic Energy",
      "Basic Fire Energy",
      "Basic Darkness Energy",
      "Basic Metal Energy",
      "Basic Fairy Energy",
      "Basic Dragon Energy",
      "Jet Energy",
      "Double Turbo Energy",
    ];

    const filteredData = allData.filter(
      (item) => !excludedNames.includes(item.name)
    );

    const uniqueItems = filteredData;
    const prevDataMap = new Map();
    uniqueItems.forEach((item) => {
      prevDataMap.set(item.name, item.history);
    });

    const cumulativeMap = new Map();
    let globalMax = 0;
    uniqueItems.forEach((item) => {
      const totalPentium = item.history.reduce(
        (acc, curr) => acc + curr.PentiumNumber,
        0
      );
      const averagePentium = item.history.length
        ? totalPentium / item.history.length
        : 0;
      cumulativeMap.set(item.name, averagePentium);
      if (averagePentium > globalMax) globalMax = averagePentium;
    });

    const result = {
      latestItems: uniqueItems,
      prevDataMap: Array.from(prevDataMap.entries()),
      cumulativeMap: Array.from(cumulativeMap.entries()),
      globalMax,
    };

    Cookies.set("topData", JSON.stringify(result), { expires: 1 });
    return {
      latestItems: uniqueItems,
      prevDataMap,
      cumulativeMap,
      globalMax,
    };
  } catch (error) {
    console.error("Error fetching data from PocketBase:", error);
    return {
      latestItems: [],
      prevDataMap: new Map(),
      cumulativeMap: new Map(),
      globalMax: 0,
    };
  }
};
