// ./src/components/Card.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PocketBase from 'pocketbase';

const Card = () => {
    const { set, setCard, setCardUnderscore } = useParams();
    const [cardDetails, setCardDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const pb = new PocketBase('https://fwk4s4o.app-palma.teide.app');
        const tcgseid = `${set}/${setCard}/${setCardUnderscore}`;

        const fetchCardDetails = async () => {
            try {
                const result = await pb.collection('results').getFullList({
                    filter: `tcgseid='${tcgseid}'`,
                });

                if (result.length > 0) {
                    setCardDetails(result[0]);
                } else {
                    setError('No details found for this card.');
                }
            } catch (error) {
                setError('Failed to fetch card details.');
            } finally {
                setLoading(false);
            }
        };

        fetchCardDetails();
    }, [set, setCard, setCardUnderscore]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="min-h-screen ledscreen p-4">
            <h1 className="text-3xl text-white font-bold text-center mb-8">Card Details</h1>
            {cardDetails && (
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-white">
                    <h2 className="text-2xl mb-4">{cardDetails.name}</h2>
                    <p><strong>Set:</strong> {cardDetails.set}</p>
                    <p><strong>Number:</strong> {cardDetails.number}</p>
                    <p><strong>Regulation:</strong> {cardDetails.regulation}</p>
                    <p><strong>Trend Price:</strong> {cardDetails.trend ? `${cardDetails.trend.toFixed(2)} €` : 'N/A'}</p>
                    <p><strong>Average Sell Price:</strong> {cardDetails.cardmarket ? `${cardDetails.cardmarket.toFixed(2)} €` : 'N/A'}</p>
                    <a href={cardDetails.cardmarketurl} target="_blank" rel="noreferrer">
                        <img src={cardDetails.imagelout} alt={cardDetails.name} className="mb-4 mx-auto" />
                    </a>
                </div>
            )}
        </div>
    );
};

export default Card;
