// src/data/menuItems.js

export const menuItems = [
  {
    title: "News",
    link: "https://www.tcgse.com/articles",
  },
  {
    title: "TCGSE",
    link: "https://www.tcgse.com",
  },
  {
    title: "Markets",
    link: "https://www.tcgse.com/markets",
  },
  {
    title: "Club",
    link: "#",
    submenu: [
      { title: "Team", link: "https://www.epicevils.com/team" },
      { title: "Clothes", link: "https://www.epicevils.com/clothes" },
      { title: "Standings", link: "https://www.epicevils.com/standings" },
    ],
  },
  {
    title: "Collab",
    link: "#",
    submenu: [
      { title: "x InZoi", link: "https://www.epicevils.com/collab" },
      { title: "Contact", link: "https://www.epicevils.com/contact" },
    ],
  },
];
