// ./src/components/Share.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CardContext } from '../objects/CardContext';
import { motion } from 'framer-motion';

const Share = () => {
    const { cards } = useParams();
    const [sharedCards, setSharedCards] = useState([]);
    const { fetchCardsByIds } = useContext(CardContext);
    const [cardQuantities, setCardQuantities] = useState({});
    const [shareLink, setShareLink] = useState('');
    const [isLinkVisible, setIsLinkVisible] = useState(false);
    const [isLinkOpened, setIsLinkOpened] = useState(false);

    useEffect(() => {
        const cardEntries = cards.split(',');
        const cardIds = {};
        cardEntries.forEach(id => {
            if (cardIds[id]) {
                cardIds[id]++;
            } else {
                cardIds[id] = 1;
            }
        });
        setCardQuantities(cardIds);
        fetchCardsByIds(Object.keys(cardIds)).then(setSharedCards);

        // Generate the share link
        const generatedLink = `https://tcgse.com/share/${cards}`;
        setShareLink(generatedLink);
    }, [cards, fetchCardsByIds]);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareLink);
            alert('¡Enlace copiado al portapapeles!');
        } catch (error) {
            console.error('Failed to copy link:', error);
        }
    };

    const revealLink = () => {
        setIsLinkVisible(true);
    };

    const openLink = () => {
        setIsLinkOpened(true);
        setIsLinkVisible(false); // Hide the link section after opening
        window.open(shareLink, '_blank');
    };

    const hideLink = () => {
        setIsLinkVisible(false);
    };

    return (
        <div className="min-h-screen ledscreen p-4">
            <h1 className="text-2xl text-white font-bold text-center mb-8">Shared Cards</h1>
            {sharedCards.length > 0 ? (
                <div className="sm:text-[18px] md:text-[14px] lg:text-[16px] xl:text-[18px]">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
                        {sharedCards.map((card) => (
                            <motion.div
                                key={card.id}
                                className="relative"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <div className="rounded-[33px] bg-[#ffe165] text-black pt-4">
                                    <p>
                                        {cardQuantities[card.id] > 1 ? (
                                            <div className="flex flex-row justify-center items-center">
                                                {cardQuantities[card.id]} cards
                                            </div>
                                        ) : (
                                            <div className="flex flex-row justify-center items-center">
                                                1 card
                                            </div>
                                        )}
                                    </p>
                                    <div className="rounded-3xl bg-[#CACACA]">
                                        <div className="flex flex-row py-2 my-2 justify-between items-center px-4 space-x-4">
                                            <h2 className="font-semibold">{card.name}</h2>
                                            <p>
                                                {card.number}/{card.set.total}
                                            </p>
                                        </div>
                                        <div className="flex flex-row py-2 my-2 justify-between items-center px-4">
                                            <p>
                                                <span className="font-semibold">Trend: </span>
                                                {parseFloat(card.cardmarket?.prices?.trendPrice || 0).toFixed(2)} €
                                            </p>
                                            <p>
                                                <span className="font-semibold">Average: </span>
                                                {parseFloat(card.cardmarket?.prices?.averageSellPrice || 0).toFixed(2)} €
                                            </p>
                                        </div>
                                        <a href={card.cardmarket?.url} target="_blank" rel="noreferrer">
                                            <img src={card.images.large} alt={card.name} className="mb-4 mx-auto rounded-2xl" />
                                        </a>
                                        <div className="sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[14px] flex flex-row py-2 my-2 justify-between bg-[#CACA] items-center px-4 rounded-b-3xl">
                                            <p className="">
                                                {card.set.name}
                                            </p>
                                            <p className="">
                                                {card.rarity}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                    <div className="flex flex-col items-center mt-8">
                        <button
                            onClick={copyToClipboard}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
                        >
                            Copy Link to Clipboard
                        </button>
                        {!isLinkVisible && !isLinkOpened && (
                            <button
                                onClick={revealLink}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4"
                            >
                                Reveal Link
                            </button>
                        )}
                        {isLinkVisible && (
                            <div className="text-center mt-4">
                                <button
                                    onClick={hideLink}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
                                >
                                    Hide Link
                                </button>
                                <h2 className="font-semibold mt-4">Share Link</h2>
                                <p className="break-words py-4 bg-gray-200 text-black rounded-lg">
                                    <span className="text-green-500">
                                        {shareLink[0]}
                                        {shareLink[1]}
                                        {shareLink[2]}
                                        {shareLink[3]}
                                        {shareLink[4]}
                                    </span>
                                    <span className="text-blue-500">
                                        {shareLink[5]}
                                        {shareLink[6]}
                                        {shareLink[7]}
                                    </span>
                                    {shareLink.slice(8)}
                                </p>
                                {/* {!isLinkOpened && (
                                    <button
                                        onClick={openLink}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                                    >
                                        Open Link
                                    </button>
                                )} */}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <p className="text-center">Loading...</p>
            )
            }
        </div >
    );
};

export default Share;
