import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import pb from '../utils/pocketbase';
import Cookies from 'js-cookie';

const AuthPage = () => {
    const [isLoginMode, setIsLoginMode] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [rgpd, setRgpd] = useState(false);
    const [terms, setTerms] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const authData = Cookies.get('pb_auth');
        if (authData) {
            const parsedAuthData = JSON.parse(authData);
            pb.authStore.save(parsedAuthData.token, parsedAuthData.model);
            navigate('/');
        }
    }, [navigate]);

    const validatePassword = () => {
        if (password.length < 16) {
            setError('Password must be at least 16 characters long.');
            return false;
        }
        if (password === email) {
            setError('Password cannot be the same as the email address.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setMessage(null);

        if (!validatePassword()) return;

        if (isLoginMode) {
            try {
                const authData = await pb.collection('users').authWithPassword(email, password);
                Cookies.set('pb_auth', JSON.stringify(authData), { expires: 7 });
                navigate('/');
            } catch (err) {
                setError(err.message || 'An error occurred during login');
            }
        } else {
            if (password !== confirmPassword) {
                setError('Passwords do not match');
                return;
            }

            if (!rgpd) {
                setError('You must accept the privacy policy.');
                return;
            }

            if (!terms) {
                setError('You must accept the terms of service.');
                return;
            }

            const data = {
                email,
                emailVisibility: true,
                password,
                passwordConfirm: confirmPassword,
                rgpd,
                terms,
                level: "848eb5riy0k5h7g",
            };

            try {
                await pb.collection('users').create(data);
                await pb.collection('users').requestVerification(email);
                setMessage('Account created successfully. Please check your email to verify your account.');
                setIsLoginMode(true);
            } catch (err) {
                setError(err.message || 'An error occurred during signup');
            }
        }
    };

    return (
        <div className="flex ledscreen flex-col items-center justify-center min-h-screen text-white">
            <form onSubmit={handleSubmit} className="p-8 rounded-md shadow-md w-80 border border-gray-700">
                <h2 className="text-2xl mb-4">{isLoginMode ? 'Login' : 'Signup'}</h2>
                <h3 className="text-md mb-4">{isLoginMode ? 'First login check email or spam to verify' : ''}</h3>
                <div className="mb-4">
                    <label className="block mb-1" htmlFor="auth-email">Email</label>
                    <input
                        id="auth-email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 rounded-md text-white bg-black border border-gray-700"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1" htmlFor="auth-password">Password</label>
                    <input
                        id="auth-password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 rounded-md text-white bg-black border border-gray-700"
                        required
                    />
                    {!isLoginMode && (
                        <p className={`text-sm ${password.length >= 16 ? 'text-green-500' : password.length >= 8 ? 'text-yellow-500' : 'text-red-500'}`}>
                            {password.length} / 16 characters
                        </p>
                    )}
                </div>
                {!isLoginMode && (
                    <>
                        <div className="mb-4">
                            <label className="block mb-1" htmlFor="signup-confirm-password">Confirm Password</label>
                            <input
                                id="signup-confirm-password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full p-2 rounded-md text-white bg-black border border-gray-700"
                                required
                            />
                            {confirmPassword !== password && confirmPassword.length > 0 && (
                                <p className="text-red-500">Passwords do not match</p>
                            )}
                        </div>
                        <div className="mb-4">
                            <input
                                type="checkbox"
                                id="rgpd"
                                checked={rgpd}
                                onChange={(e) => setRgpd(e.target.checked)}
                            />
                            <label htmlFor="rgpd" className="ml-2">
                                I accept the <a href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="text-blue-500">privacy policy</a>.
                            </label>
                        </div>
                        <div className="mb-4">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={terms}
                                onChange={(e) => setTerms(e.target.checked)}
                            />
                            <label htmlFor="terms" className="ml-2">
                                I accept the <a href="/terms" target="_blank" rel="noopener noreferrer" className="text-blue-500">terms of service</a>.
                            </label>
                        </div>
                    </>
                )}
                {error && <div className="text-red-500 mb-4">{error}</div>}
                {message && <div className="text-green-500 mb-4">{message}</div>}
                <button
                    type="submit"
                    className={`bg-blue-600 p-2 rounded-md w-full ${(!isLoginMode && (!rgpd || !terms)) ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!isLoginMode && (!rgpd || !terms)}
                >
                    {isLoginMode ? 'Login' : 'Signup'}
                </button>
                <button
                    type="button"
                    onClick={() => setIsLoginMode(!isLoginMode)}
                    className="mt-4 text-blue-400 hover:underline"
                >
                    {isLoginMode ? "Don't have an account? Sign up" : 'Already have an account? Login'}
                </button>
            </form>
        </div>
    );
};

export default AuthPage;
