/* ./src/components/actions/SettingsView.js */

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import pb from '../utils/pocketbase';

const SettingsView = ({ columns, visibleColumns, handleColumnVisibilityChange, handleSaveViewSettings }) => {
    const [allowedColumns, setAllowedColumns] = useState([]);
    const [forcedColumns, setForcedColumns] = useState([]);
    const authData = Cookies.get('pb_auth');
    const isAuthenticated = !!authData;
    const [userLevel, setUserLevel] = useState('guest');

    useEffect(() => {
        const fetchColumns = async () => {
            try {
                const result = await pb.collection('features').getFullList({
                    sort: 'item',
                });

                // Determine allowed columns based on user level
                const allowedColumnKeys = result
                    .filter(item => {
                        if (userLevel === 'guest') return true; // Show all for guest but do not allow customization
                        if (userLevel === 'free') return item.activated && item.level === 'free';
                        if (userLevel === 'basic') return item.activated && ['free', 'basic'].includes(item.level);
                        if (userLevel === 'stage 1') return item.activated && ['free', 'basic', 'stage 1'].includes(item.level);
                        if (userLevel === 'stage 2') return item.activated && ['free', 'basic', 'stage 1', 'stage 2'].includes(item.level);
                        if (userLevel === 'stage 3') return item.activated && ['free', 'basic', 'stage 1', 'stage 2', 'stage 3'].includes(item.level);
                        return false;
                    })
                    .map(item => item.item);

                // Columns that must be shown and cannot be hidden for guests or free users
                const forcedColumnKeys = result
                    .filter(item => !item.activated && (item.level === 'free' || (!isAuthenticated && item.level === 'free')))
                    .map(item => item.item);

                setAllowedColumns(allowedColumnKeys);
                setForcedColumns(forcedColumnKeys);

            } catch (error) {
                console.error('Failed to fetch allowed columns:', error);
            }
        };

        // Fetch the user's level
        const fetchUserLevel = async () => {
            const userId = pb.authStore.model?.id;
            if (userId) {
                try {
                    const userInfo = await pb.collection('users').getOne(userId);
                    setUserLevel(userInfo.level || 'guest');
                } catch (error) {
                    console.error('Failed to fetch user level:', error);
                    setUserLevel('guest');
                }
            } else {
                setUserLevel('guest');
            }
        };

        fetchUserLevel();
        fetchColumns();
    }, [userLevel, isAuthenticated]);

    const handleCheckboxChange = (columnKey) => {
        if (!isAuthenticated) {
            return; // Guests cannot customize columns
        }
        handleColumnVisibilityChange(columnKey);
    };

    return (
        <div className="flex flex-col m-2 p-4 bg-gray-800 rounded-lg mt-4">
            {isAuthenticated && (
                <h2 className="text-lg mb-2">Customize Columns</h2>
            )}
            {!isAuthenticated && (
                <h2 className="text-lg mb-2">Signup to fully customize columns</h2>
            )}
            <div className="flex flex-wrap gap-2">
                {columns.map((column) => {
                    const isGuestColumn = allowedColumns.includes(column.key);
                    const isForcedColumn = forcedColumns.includes(column.key);
                    const isDisabledForGuest = !isAuthenticated;

                    return (
                        <div key={column.key} className="flex items-center">
                            <input
                                type="checkbox"
                                checked={visibleColumns[column.key] || isForcedColumn}
                                disabled={isDisabledForGuest}
                                onChange={() => handleCheckboxChange(column.key)}
                                className="mr-2"
                            />
                            <label className={`${(isDisabledForGuest || isForcedColumn) ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                {column.label}
                            </label>
                        </div>
                    );
                })}
            </div>
            {isAuthenticated && (
                <button
                    onClick={handleSaveViewSettings}
                    className="mt-4 bg-blue-700 hover:bg-blue-900 w-full p-2 rounded"
                >
                    Save View Settings
                </button>
            )}
        </div>
    );
};

export default SettingsView;
