import { useEffect, useState } from "react";

// Array of advertising phrases
const phrases = [
  "Ready to make smarter moves in Pokémon trading? Learn how in our latest blog post!",
  "Wondering which Pokémon cards are climbing in value? Check our market analysis!",
  "Discover the secrets of successful Pokémon TCG trading on our blog!",
  "Maximize your trading profits! Read how top players spot trends in the TCG market.",
  "Stay ahead of the curve! Get expert tips on Pokémon TCG strategies in our blog.",
  "Find out how to keep your Pokémon card collection profitable with our in-depth guides!",
  "Looking to trade smarter? Explore the essential tactics for Pokémon TCG success!",
  "Explore the future of Pokémon card values—don't miss our latest market predictions!",
  "Ready to trade like a pro? Discover proven strategies to improve your TCG performance!",
  "The hottest Pokémon cards right now? Check out the cards to watch in our latest post!",
  "Learn the art of trading Pokémon cards with our in-depth guides—only on the blog!",
  "Don't miss the biggest Pokémon card deals—stay updated with our market insights!",
  "Want to know which cards are worth holding onto? Our blog has all the answers!",
  "Get the inside scoop on rising Pokémon card values and make informed trades!",
  "Thinking about getting in rare Pokémon cards? Read our expert guide first!",
  "Your Pokémon cards could be worth more than you think—find out how to assess value!",
  "Maximize your TCG portfolio with our expert tips on trading and collecting.",
  "Trade better, trade smarter! See how top traders make decisions on TCGSE.",
  "Stay updated with the latest Pokémon TCG news and trends on our blog!",
  "Get the edge in the trading market—explore our advanced card evaluation tips!",
  "Which Pokémon cards are set to rise? See our trend analysis for valuable insights!",
  "Want to stay ahead in the TCG market? Our blog reveals key strategies for success.",
  "Join the best in the game—find out what expert traders are reading on our blog!",
  "Find out which cards to buy, sell, or hold with our TCG market insights!",
  "Boost your Pokémon TCG collection value—explore our expert advice today!",
  "Learn how to build a high-value Pokémon card portfolio with our latest tips.",
  "The TCG market is constantly changing—find out how to stay ahead of the curve!",
  "Stay one step ahead! Discover the rising stars in Pokémon card trading.",
  "Unlock the secrets to becoming a top Pokémon trader—read more on our blog!",
  "Ready to level up your Pokémon card trading game? Get actionable tips on our blog!",
  "The market is shifting—find out what that means for your collection on TCGSE.",
  "Looking for rare Pokémon cards? Learn how to track down the best deals!",
  "Check out the Pokémon cards with the highest ROI—explore our in-depth analysis.",
  "Want to predict future card values? Our blog reveals the data behind the trends!",
  "Find out what makes a Pokémon card a must-have! Explore our detailed guides.",
  "The world of TCG is fast-paced—see how you can stay ahead with our expert advice!",
  "Discover the Pokémon cards most likely to increase in value—read our expert predictions!",
  "Expand your collection and your profits! Get trading tips from the pros on our blog.",
  "Maximize your Pokémon TCG binder with the latest market analysis.",
  "Is now the time to sell? Learn when to make your moves with our expert insights!",
  "Unlock the value hidden in your Pokémon cards—find out how on our blog.",
  "Become a TCG trading expert—explore our blog for essential market tips!",
  "Which Pokémon cards are on the rise? Stay informed with our market reports!",
  "Looking to trade up? Get the best advice on how to improve your collection's value!",
  "Know when to hold and when to sell—discover expert strategies for Pokémon TCG!",
  "Turn your Pokémon cards into a winning possible choice! Learn how on TCGSE.",
  "Stay competitive in the TCG market—see the trends you can’t afford to miss!",
  "Are your Pokémon cards future goldmines? Discover their potential with our analysis!",
  "Keep up with the evolving TCG market—read the latest expert predictions on our blog!",
  "Want to get ahead in the Pokémon card game? Learn from the best in our expert blog.",
]; /* &rsquo; */

const RandomAd = () => {
  const [randomPhrase, setRandomPhrase] = useState("");

  useEffect(() => {
    // Select a random phrase from the array
    const randomIndex = Math.floor(Math.random() * phrases.length);
    setRandomPhrase(phrases[randomIndex]);
  }, []);

  return (
    <div className="flex flex-row space-x-2 mt-8">
      <a
        href="/articles"
        className="" /* bg-blue-500 rounded-full px-4 text-sm  ml-2  */
      >
        <p className="mt-2">
          {randomPhrase}
          <span className="text-blue-500 px-3 py-1">Read more</span>
        </p>
      </a>
    </div>
  );
};

export default RandomAd;
