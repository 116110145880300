import PocketBase from "pocketbase";

const pb = new PocketBase(
  "https://ows4kkks4c8w8skc84o444s4.app-palma.teide.app"
);

// Disable auto-cancellation for all requests
pb.autoCancellation(false);

export const getPostList = async (page = 1, perPage = 10) => {
  return await pb.collection("posts").getList(page, perPage, {
    sort: "-created",
    $autoCancel: false, // Disable auto-cancellation
  });
};

export const getPost = async (id) => {
  return await pb.collection("posts").getOne(id, {
    $autoCancel: false, // Disable auto-cancellation
  });
};

export default pb;
