// MiniChart.js

import React from "react";
import { Line } from "react-chartjs-2";

const MiniChart = ({ data }) => {
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      y: { display: false, beginAtZero: true },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    elements: {
      point: { radius: 0 },
    },
  };

  return (
    <div className="w-24 h-10">
      <Line data={data} options={options} width={80} height={40} />
    </div>
  );
};

export default MiniChart;
