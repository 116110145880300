// ./src/components/algos/PriceComparison.js

import React from 'react';
import { motion } from 'framer-motion';

const PriceComparison = ({ trendPrice, averageSellPrice }) => {
    const trend = parseFloat(trendPrice?.toFixed(2) || 0);
    const average = parseFloat(averageSellPrice?.toFixed(2) || 0);
    const trendStatus = trend > average ? 'text-green-500' : trend < average ? 'text-red-500' : 'text-white';
    const trendIcon = trend > average ? '↑' : trend < average ? '↓' : '⇌';
    const averageStatus = average > trend ? 'text-green-500' : average < trend ? 'text-red-500' : 'text-white';
    const averageIcon = average > trend ? '↑' : average < trend ? '↓' : '⇌';

    return (
        <motion.div
            className="flex flex-row gap-2 items-center justify-evenly"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="flex items-center gap-1">
                <span className={trendStatus}>{`€${trend.toFixed(2)}`}</span>
                <span>{trendIcon}</span>
            </div>
            <div className="flex items-center gap-1">
                <span className={averageStatus}>{`€${average.toFixed(2)}`}</span>
                <span>{averageIcon}</span>
            </div>
        </motion.div>
    );
};

export default PriceComparison;
